import styled from "styled-components";
import chart from "./images/banner.png";

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e65f05;

  p {
    color: white;
    line-height: 36px;
    font-size: 18px;
    text-shadow: rgb(0, 0, 0) 0px 0px 10px, rgb(0, 0, 0) 0px 0px 10px;
  }

  @media (min-width: 420px) {
    padding: 0 50px;
  }

  @media (min-width: 760px) {
    p {
      font-size: 20px;
    }
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 100;
  // max-width: 1180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: Coming Soon;

  @media (min-width: 992px) {
    flex-direction: row;
    max-width: 95%;
  }
`;

const LeftGrid = styled.div`
  display: flex;
  justify-content: center;
  order: 2;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  @media (min-width: 992px) {
    order: 1;
    flex-basis: 40%;
    max-width: 40%;

    img {
      width: 600px;
    }
  }
`;

const RightGrid = styled.div`
  order: 1;
  text-align: center;

  h2 {
    font-size: 48px;
    color: #fff;
    font-family: Coming Soon;
    text-shadow: rgb(87, 20, 20) 0px 0px 10px, rgb(248, 248, 251) 0px 0px 10px;
  }

  @media (min-width: 360px) {
    h2 {
      font-size: 50px;
    }
  }

  @media (min-width: 480px) {
    h2 {
      font-size: 60px;
    }
  }

  @media (min-width: 992px) {
    flex-basis: 55%;
    max-width: 55%;
    order: 2;

    h2 {
      font-size: 80px;
    }
  }

  @media (min-width: 1180px) {
    h2 {
      font-size: 80px;
    }
  }
`;

const About = () => {
  return (
    <Section id="about">
      <Container>
        <LeftGrid>
          <img src={chart} alt="" />
        </LeftGrid>
        <RightGrid>
          <h2>What is $BERA?</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia quam
            consequuntur quidem velit exercitationem cum nobis? Esse harum
            asperiores quod.{" "}
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorem at
            sapiente sequi cum maxime odio recusandae quisquam libero,
            perferendis laudantium, earum molestias fuga, voluptatibus
            reiciendis maiores ab odit debitis nemo.
          </p>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente
            similique doloribus ut nesciunt, nemo facere.
          </p>
        </RightGrid>
      </Container>
    </Section>
  );
};

export default About;
