import Layout from "./_shared/Layout";
import SideBar from "./_shared/SideBar";
import Banner from "./Banner";
import About from "./About";

const App = () => {
  return (
    <Layout>
      <SideBar />
      <Banner />
      <About />
    </Layout>
  );
};

export default App;
