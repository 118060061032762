import styled from "styled-components";
import logo from "./images/logo.png";

import bera1 from "./images/bera1.png";
import bera2 from "./images/bera2.png";
import bera3 from "./images/bera3.png";
import bera4 from "./images/bera4.png";
import bera5 from "./images/bera5.png";

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .title {
    position: relative;
    z-index: 100;
    color: #fff;
    color: white;
    text-shadow: rgb(253, 0, 0) 0px 0px 10px, rgb(98, 43, 43) 0px 0px 10px;
    text-align: center;
    position: absolute;
    margin-top: -16rem;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
  }

  h1 {
    font-family: Coming Soon;
  }

  @media (min-width: 360px) {
    .title {
      margin-top: -20rem;
    }

    h1 {
      font-size: 60px;
    }
  }

  @media (min-width: 480px) {
    h1 {
      font-size: 70px;
    }
  }

  @media (min-width: 760px) {
    h1 {
      font-size: 90px;
    }
  }

  @media (min-width: 1180px) {
    h1 {
      font-size: 110px;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;
  margin-top: 150px;

  a {
    padding: 24px 48px;
    display: -moz-box;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    font-weight: 700;
    font-size: 24px;
    border-radius: 5px;
    border: none;
    color: #7bdaec;
    font-family: Coming Soon;
    margin: 0 24px 24px;
    background-color: #e65f05;
    color: white;
    text-shadow: rgb(96, 36, 36) 0px 0px 10px, rgb(0, 0, 0) 0px 0px 10px;
    border: 2px solid #fff;
  }

  a:hover {
    box-shadow: 4px 4px 15px 3px rgb(0, 0, 0);
  }

  .buy {
    color: white;
    text-shadow: rgb(204, 0, 6) 0px 0px 10px, rgb(125, 0, 4) 0px 0px 10px;
  }

  .buy:hover {
    box-shadow: 4px 4px 15px 3px rgb(162, 0, 0);
  }

  img {
    max-width: 80px;
    margin: 0 14px 0 16px;
  }

  @media (min-width: 360px) {
  }

  @media (min-width: 480px) {
  }

  @media (min-width: 760px) {
    margin-top: 60px;
    flex-direction: row;
  }

  @media (min-width: 1180px) {
    margin-top: 0;

    a {
      font-size: 36px;
      margin: 0 24px 0;
    }
  }
`;

const BerasContainer = styled.div`
  position: absolute;
  display: flex;
`;

const Banner = () => {
  return (
    <Section id="home">
      <div className="title">
        <h1>0IQ Beras</h1>
      </div>
      <BerasContainer>
        <img src={bera1} alt="bera" />
        <img src={bera2} alt="bera" />
        <img src={bera3} alt="bera" />
        <img src={bera4} alt="bera" />
        <img src={bera5} alt="bera" />
      </BerasContainer>
      <ButtonsContainer>
        <a href="/#airdrop" className="buy">
          <img src={logo} alt="Shiba AInu Logo" /> Mint
        </a>
        <a href="/#airdrop">Buy Now</a>
      </ButtonsContainer>
    </Section>
  );
};

export default Banner;
